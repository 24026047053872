<template lang="">
    <div class="field">
        <div class="grid formgrid mt-8">
            <div class="field col-12">
                <div class="card-container">
                    <div class="block font-bold text-center p-2 mb-3"><h1>Convocação Concluída com Sucesso</h1></div>
                    <div class="block font-bold text-center mb-1">Clientes selecionados</div>
                    <div class="block text-center mb-3">
                        <div v-for="convocacaoCliente in clientes" :key="convocacaoCliente.id">
                            <i v-if="convocacaoCliente.msgErro" v-tooltip.bottom="convocacaoCliente.msgErro" class="pi pi-info-circle" :style="{ color: 'red' }"></i>
                            <span>{{ convocacaoCliente.cliente.name }}</span>
                        </div>
                    </div>
                    <div class="block font-bold text-center mb-1">Período</div>
                    <div class="block text-center mb-3">
                        {{ $filters.formatDateOnly(form.periodo) }}
                    </div>
                </div>
            </div>
            <div class="col-12 p-3">
                <div class="flex justify-content-end">
                    <div class="flex align-items-center justify-content-center w-50rem font-bold text-white">
                        <Button label="Fechar" icon="pi pi-check" class="p-button p-button-success mr-2 w-30rem" @click="onClickFechar" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import ConvocacaoService from '../services/ConvocacaoService';

export default {
    data() {
        return {
            $service: null,
            form: {},
            clientes: []
        };
    },
    mounted() {
        this.$service = new ConvocacaoService();
        this.load();
    },
    methods: {
        onClickFechar() {
            this.$router.push(`/convocacao-exames/`);
        },
        async load() {
            const [responseConvocacao, responseClientes] = await Promise.all([
                this.$service.findById(this.$route.params.id),
                this.$service.clientes(this.$route.params.id)
            ]);

            this.form = responseConvocacao.data;
            this.clientes = responseClientes.data;
            await this.autoLoad();
        },
        async autoLoad() {
            const responseConvocacao = await this.$service.findById(this.$route.params.id);
            this.form = responseConvocacao.data;
            if (this.form.percentual < 100) {
                setTimeout(async () => {
                    await this.autoLoad();
                }, 5000);
            }
        }
    }
};
</script>
